body {
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: #000;
  /*  height: 100vh;
  width: 100vw;
  background: url("./assets/18-light.jpeg");
  background-size: cover;
  background-repeat: no-repeat; */
  /* background-position-y: -10vh; */
  /* background-position: center center; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Slideshow styling */

ul.indicators {
  /* margin-top: -40px; */
  position: absolute;
  width: 100%;
  z-index: 10;
  padding: 0;
  top: 70vh;
  gap: 10px;
}

ul.indicators .each-slideshow-indicator:before {
  width: 15px !important;
  height: 15px !important;
  background: #fff !important;
}

/* .indicators {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border: 1px #666 solid;
}

.indicators.active {
  color: #fff;
  background: #666;
} */

/* @supports (-webkit-touch-callout: none) and (max-width: 500px) {
  /* CSS specific to small iOS devices 
  ul.indicators {
    top: 85vh;
  }
} */

@media screen and (max-width: 500px) {
  ul.indicators {
    top: 75vw;
  }
}
