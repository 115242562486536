#event-gallery {
  background: #000;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.grid-images {
  width: 80%;
  /* display: flex;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2%;
  margin: 40px 2%;
  justify-content: center;
}

.grid-images a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.grid-img-wrapper {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
}

.grid-images img {
  width: 100%;
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  min-height: 100%;
}

.event-text-wrapper {
  background: rgba(255, 255, 255, 0.81);
  border: 1px solid #000;
  width: 70%;
  margin-top: 10%;
}

#event-gallery p {
  width: 70%;
  text-align: center;
  padding: 20px 0;
  margin: 0 auto;
  font-size: 1.8rem;
}

@media only screen and (max-width: 768px) {
  .grid-images {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
