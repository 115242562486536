#about-us {
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-us-text {
  width: 70%;
  padding: 20px 0;
}
