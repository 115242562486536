.header {
  width: 80%;
  height: 20vh;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10%;
  background: #000;
  color: #fff;
}

.logo-wrapper {
  height: 100%;
  width: 250px;
  z-index: 20;
  display: flex;
  align-items: center;
}

.logo {
  width: 90%;
}

.hamburger,
.close {
  display: none;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin: 0 1rem;
  transform: 0;
  -webkit-transform: translate3d(0, 0, 0);
}

.header a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

.header button {
  font: inherit;
  background-color: transparent;
  border: 1px solid #f9f9f9;
  color: #f9f9f9;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.header button:hover {
  background-color: #f9f9f9;
  color: black;
}

.header-link:hover {
  display: inline-block;
  position: relative;
  color: #f9f9f9;
}

.header-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f9f9f9;
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.header-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.sidebar {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    width: 90%;
  }
  .logo {
    font-size: 3rem;
  }
  .hamburger {
    display: block;
    margin-right: 5%;
    font-size: 30px;
  }
  .close {
    display: block;
    margin-right: 5%;
    font-size: 40px;
  }
  .links {
    display: none;
  }

  .sidebar {
    display: block;
    position: fixed;
    width: 50%;
    background: black;
    height: 100vh;
    z-index: 999;
    text-align: center;
  }

  .sidebar h2 {
    text-decoration: underline;
  }

  .sidebar-links {
    list-style: none;
    /* padding: 0; */
    width: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    text-align: left;
  }

  .sidebar-links li {
    height: 2rem;
    margin: 1rem 0;
    /*  width: 100%; */
  }

  .sidebar-links a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }

  .sidebar-links a:hover {
    display: inline-block;
    position: relative;
    color: #f9f9f9;
  }

  .sidebar-links a:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #f9f9f9;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  .sidebar-links a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }
}

@media screen and (max-width: 500px) {
  .sidebar-links {
    padding-left: 20px;
  }
}
