#contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

form {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

#name,
#email {
  width: 40%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: solid 1.5px #000;
  font-size: 16px;
}

#message {
  height: 250px;
  width: 90%;
  resize: none;
  padding: 0.75rem;
  margin-bottom: 1rem;
  font-size: 16px;
}

#submit {
  color: #fff;
  background: #000;
  border: 1px solid #fff;
  height: 40px;
  width: 100px;
  font-size: 16px;
  cursor: pointer;
}

#submit:hover {
  background: #afacac;
  color: #000;
  border: 1px solid #000;
}

.thank-you {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 400px) {
  #name,
  #email {
    width: 100%;
  }
}
