#contact-us {
  display: grid;
  grid-template-columns: 1fr;
  background: #000;
  height: 20vh;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 5%;
  font-size: 30px;
}

.social-icons a {
  color: #fff;
}

.social-icons svg {
  border: 1px solid #fff;
  padding: 5px;
}

footer ul {
  list-style: none;
}
