/* 
  Put all css in index.css as it may be that these classes aren't being
  accessed without using classes[""]... in the Slideshow.js file
*/

/* .zoom-wrapper div on slide show img may be causing the 
  the wide pages on mobile!!
*/

.slide-container {
  position: relative;
}

.slide-img {
  margin: 0 auto;
  width: 100vw;
  height: 80vh;
}

.slide-container ul.indicators {
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 90vh;
}

.slide-container ul.indicators .each-slideshow-indicator:before {
  width: 15px;
  height: 15px;
  background: #fff;
}

.react-slideshow-container + ul.indicators li {
  margin: 0 8px;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator {
  opacity: 0.5;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:hover,
.react-slideshow-container + ul.indicators .each-slideshow-indicator.active,
.react-slideshow-container + ul.indicators .each-slideshow-indicator:focus {
  opacity: 1s;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  background: #fff;
}

@media screen and (max-width: 500px) {
  .slide-img {
    width: 90vw;
    height: 90vw;
  }

  .slide-container {
    margin: 20px 0;
  }
}
